body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapp_nav .background {
  display: none; }

.wrapp_nav .nav {
  display: block !important;
  position: absolute;
  top: 0;
  right: -90px; }

.wrapp {
  padding-top: 31px; }
  .wrapp .container {
    position: relative; }
    .wrapp .container .headnig {
      position: absolute;
      left: 15px; }
      .wrapp .container .headnig h1 {
        font-weight: 700; }
        .wrapp .container .headnig h1::after {
          margin: 0 !important; }
    .wrapp .container .position {
      top: 22.1px;
      position: absolute;
      right: 15px;
      color: #DA231B; }
      .wrapp .container .position h3 {
        font-size: 20px;
        line-height: 24px; }
      .wrapp .container .position a {
        color: #1F1A16;
        text-decoration: none; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .wrapp {
    padding-top: unset; }
    .wrapp .container .heading h1 {
      font-size: 25px; }
      .wrapp .container .heading h1::after {
        margin-top: 0; }
    .wrapp .container .position {
      top: 13px; }
      .wrapp .container .position h3 {
        font-size: 16px; }
        .wrapp .container .position h3 a {
          font-size: 16px; } }

.about_Section1 {
  margin-top: 67px; }
  .about_Section1 ul li {
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 8px; }
  .about_Section1 .LeftDots .slika {
    height: 350px;
    width: 100%;
    object-fit: contain; }
  .about_Section1 h4 {
    color: #DA231B;
    font-weight: 600; }
  .about_Section1 h1 {
    margin-bottom: 26px !important; }
    .about_Section1 h1::after {
      display: none; }
  .about_Section1 h2 {
    font-size: 42px;
    font-weight: 700;
    margin-top: 20px;
    padding-left: 15px; }
  .about_Section1 h3 {
    font-size: 50px;
    font-weight: 700;
    margin-top: 20px;
    line-height: 60px; }
  .about_Section1 p {
    padding-bottom: 30px; }
  .about_Section1 .content {
    padding-bottom: 115px;
    padding-top: 25px; }
    .about_Section1 .content .LeftDots {
      position: relative;
      width: 350px; }
      .about_Section1 .content .LeftDots .dotsl {
        position: absolute;
        right: -45px;
        bottom: -40px;
        z-index: -1; }
  .about_Section1 .RightDots {
    position: relative; }
    .about_Section1 .RightDots .dotsr {
      position: absolute;
      bottom: -40px;
      left: -45px;
      z-index: -1; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .wrapp_nav .nav {
    display: none !important; }
  .about_Section1 {
    margin-top: 0px; }
    .about_Section1 h2 {
      display: none; }
    .about_Section1 img {
      width: 100%; }
    .about_Section1 h3 {
      font-size: 32px; }
    .about_Section1 .content {
      padding: 0; }
      .about_Section1 .content .LeftDots {
        width: auto; }
        .about_Section1 .content .LeftDots .dotsl {
          display: none; }
    .about_Section1 .RightDots .dotsr {
      display: none; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .wrapp_nav .ss .background {
    display: none; }
  .wrapp_nav .ss .nav {
    display: none !important; }
  .wrap {
    padding-top: 0; }
  .about_Section1 h2 {
    display: none; }
  .about_Section1 img {
    text-align: center; }
  .about_Section1 p {
    padding-bottom: 40px !important; }
  .about_Section1 .content {
    padding-bottom: 60px; }
    .about_Section1 .content .LeftDots .dotsl {
      right: -24px;
      bottom: -24px; }
  .about_Section1 .RightDots .dotsr {
    left: -24px;
    bottom: -24px; } }

.background {
  position: absolute;
  right: -130px;
  top: 0;
  overflow-x: hidden !important; }

.nav {
  display: none !important; }

.activ {
  color: #DA231B !important; }

.wrapper {
  display: none; }

.down {
  background-color: white;
  position: fixed !important;
  width: 100%;
  z-index: 22;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }

.navbarWrapper {
  position: relative;
  height: 150px;
  z-index: 2; }

.language {
  display: flex; }
  .language svg {
    height: 20px;
    width: 20px; }
  .language ul {
    display: flex;
    padding: 0; }
    .language ul li {
      list-style-type: none;
      text-decoration: none;
      padding: 0 10px; }
      .language ul li a {
        text-decoration: none;
        cursor: pointer; }
      .language ul li:last-child {
        padding-right: 0; }

.navbar {
  height: 150px;
  top: 0; }
  .navbar .container {
    position: relative; }
    .navbar .container .wrapper {
      border-bottom: 1px solid #707070;
      justify-content: space-between;
      display: flex; }
      .navbar .container .wrapper p {
        font-size: 20px;
        margin-left: 18px;
        margin-bottom: 0; }
      .navbar .container .wrapper .phone {
        display: flex;
        padding-bottom: 17px; }
      .navbar .container .wrapper .socialMedia {
        display: flex;
        padding-bottom: 17px; }
        .navbar .container .wrapper .socialMedia svg {
          margin-left: 18px; }
    .navbar .container .navbar-nav .nav-item {
      margin-top: 11px;
      margin-right: 49px; }
      .navbar .container .navbar-nav .nav-item .nav-link {
        padding: 0 !important;
        color: #1F1A16;
        font-size: 20px;
        font-weight: 600;
        text-decoration: none; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .language ul li:last-child {
    padding-right: 10px; }
  .background {
    display: none; }
  .wrapper {
    background-color: white;
    margin-top: 0;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: space-between; }
    .wrapper .phone {
      display: flex;
      margin-left: 15px; }
      .wrapper .phone P {
        margin-left: 5px; }
    .wrapper .socialMedia {
      display: flex;
      position: absolute;
      right: 15px; }
      .wrapper .socialMedia svg {
        margin-left: 5px; }
  .navbar-toggler {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
    .navbar-toggler:focus, .navbar-toggler:hover {
      outline: none; }
  .navbar {
    height: auto;
    position: unset;
    top: unset;
    position: fixed !important;
    width: 100%;
    margin: 0;
    z-index: 5;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }
    .navbar .container {
      top: unset; }
      .navbar .container .navbar-brand svg {
        width: 90px !important; }
      .navbar .container .wrapper {
        display: none; }
      .navbar .container .navbar-nav .nav-item {
        margin-top: 11px;
        margin-right: 49px; }
        .navbar .container .navbar-nav .nav-item a {
          font-size: 18px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .backgroundImages {
    position: relative;
    top: -25px; }
    .backgroundImages .background {
      right: -209px;
      top: 93px; }
  .wrapper {
    text-align: center;
    background-color: white;
    display: flex;
    padding: 10px 0 10px 0;
    justify-content: space-between; }
    .wrapper .phone {
      display: flex;
      padding-left: 20px; }
    .wrapper .socialMedia {
      display: flex;
      position: absolute;
      right: 20px; }
      .wrapper .socialMedia svg {
        margin-left: 10px; }
  .navbar {
    width: 100%;
    position: fixed !important;
    height: auto;
    background-color: white;
    z-index: 5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    top: 48px; } }

@media screen and (max-width: 1024px) and (min-width: 991px) {
  .navbar .container .navbar-nav .nav-item {
    margin-right: 30px !important; }
    .navbar .container .navbar-nav .nav-item a {
      font-size: 16px !important; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .backgroundImages {
    top: -25px;
    position: relative; }
    .backgroundImages .background {
      right: -392px; }
  .navbar .container .navbar-nav .nav-item {
    margin-right: 20px; } }

.lightbox {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background-color: #333333;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1213113212; }
  .lightbox .container {
    background: #333333; }
  .lightbox img {
    height: 100vh;
    object-fit: contain;
    margin-top: 40px; }
  .lightbox .fa-times {
    color: white;
    font-size: 42px !important;
    position: absolute;
    top: 60px;
    right: 50px;
    z-index: 2; }
    .lightbox .fa-times:hover {
      cursor: pointer; }
  .lightbox .fa-angle-left {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    left: 40px; }
    .lightbox .fa-angle-left:hover {
      cursor: pointer; }
  .lightbox .fa-angle-right {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    right: 40px; }
    .lightbox .fa-angle-right:hover {
      cursor: pointer; }

.pictures {
  position: relative; }
  .pictures img {
    display: block;
    margin: auto;
    position: relative;
    top: 35%;
    height: 255px;
    object-fit: cover; }

.overlay {
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  background-color: #d67a7a; }
  .overlay img {
    height: unset !important; }

.pictures:hover .overlay {
  opacity: 0.8; }
  .pictures:hover .overlay img {
    margin: auto;
    display: block;
    position: relative;
    top: 35%; }

.HowWeWork {
  margin-top: 50px;
  background-color: #F6F7F9; }
  .HowWeWork .content {
    padding-top: 70px; }
    .HowWeWork .content .image {
      padding-top: 20px; }
    .HowWeWork .content p {
      line-height: 34px; }
      .HowWeWork .content p:last-child {
        color: #ff0000; }
  .HowWeWork .companyinfo {
    padding-top: 170px;
    padding-bottom: 87px; }
    .HowWeWork .companyinfo svg {
      float: left; }
    .HowWeWork .companyinfo .info {
      position: relative;
      left: 30px; }
    .HowWeWork .companyinfo h2 {
      margin: 0;
      font-size: 60px;
      line-height: 72px;
      font-weight: 700; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .HowWeWork .content h1 {
    padding-top: 20px; }
  .HowWeWork .content p {
    line-height: 28px; }
  .HowWeWork .companyinfo {
    padding-bottom: 40px;
    padding-top: 70px; }
    .HowWeWork .companyinfo svg {
      float: unset; }
    .HowWeWork .companyinfo .info {
      left: unset; }
      .HowWeWork .companyinfo .info h2 {
        font-size: 25px;
        line-height: 50px; }
      .HowWeWork .companyinfo .info p {
        font-size: 16px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .HowWeWork .companyinfo {
    text-align: center;
    padding-bottom: 40px; }
    .HowWeWork .companyinfo svg {
      float: unset;
      margin-bottom: 10px; }
    .HowWeWork .companyinfo .info {
      left: unset; }
      .HowWeWork .companyinfo .info h2 {
        font-size: 60px;
        line-height: 50px;
        padding-bottom: 10px; }
      .HowWeWork .companyinfo .info p {
        font-size: 23px; } }

.about_Section1 h1 {
  margin: 0; }

.about_Section1 p {
  padding-top: 0;
  padding-bottom: 52px; }

.about_Section1 img {
  margin-bottom: 30px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .about_Section1 .imageG {
    width: 100%; }
  .about_Section1 .lightbox .carousel {
    width: 100%; } }

.singlePage .headText h3 {
  font-size: 23px;
  color: #DA231B;
  line-height: 28px;
  margin: 0;
  padding-bottom: 12px; }

.singlePage .headText p {
  margin: 0;
  padding-bottom: 26px; }

.singlePage .detailImage {
  width: 100%; }

.singlePage .wrapp_news .slide img {
  margin: 0; }

.singlePage .wrapp_news .slide .content {
  margin: 0;
  padding-top: 21px;
  padding-bottom: 0; }
  .singlePage .wrapp_news .slide .content .date {
    display: none; }
  .singlePage .wrapp_news .slide .content .text {
    margin: 0; }
    .singlePage .wrapp_news .slide .content .text a h4 {
      margin-bottom: 4px;
      color: #1F1A16;
      font-weight: 600; }
    .singlePage .wrapp_news .slide .content .text h3 {
      display: block !important;
      font-size: 23px;
      line-height: 28px;
      margin: 0;
      padding-bottom: 12px;
      color: #DA231B; }
    .singlePage .wrapp_news .slide .content .text p {
      padding-bottom: 24px; }
    .singlePage .wrapp_news .slide .content .text a h6 {
      padding: 0;
      margin-bottom: 26px; }

.singlePage .wrapp_news p {
  padding-bottom: 30px; }
  .singlePage .wrapp_news p hr {
    height: 1px; }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .no-pa {
    padding: 0 15px 15px 0 !important; }
  .singlePage .wrapp_news .slide {
    margin-top: 20px; }
    .singlePage .wrapp_news .slide .content .text p {
      height: 100%; }
  .singlePage .wrapp_news p hr {
    display: none; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .singlePage .detailImage {
    width: 100%; } }

.slide .content {
  margin-top: 40px;
  display: flex; }
  .slide .content .date {
    border-radius: 10px;
    color: #FFFFFF;
    text-align: center;
    float: left;
    background-color: #DA231B;
    max-height: 140px;
    min-width: 140px; }
    .slide .content .date h2 {
      font-size: 64px;
      font-weight: 600; }
      .slide .content .date h2::after {
        content: '';
        display: block;
        position: relative;
        width: 120px;
        height: 1px;
        background-color: #FFFFFF;
        left: 6px; }
    .slide .content .date p {
      font-size: 23px;
      font-weight: 600; }
  .slide .content .text {
    margin-left: 40px; }
    .slide .content .text a {
      color: #1F1A16;
      text-decoration: none; }
      .slide .content .text a h6 {
        padding-top: 24px;
        font-size: 23px;
        font-weight: 400;
        line-height: 28px;
        padding-bottom: 94px; }
        .slide .content .text a h6 .btn {
          margin-right: 8px;
          text-align: center;
          background: url(/static/media/arrowRight.d1b5f880.svg) center no-repeat;
          position: relative;
          text-decoration: none;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          background-color: #1F1A16; }
      .slide .content .text a h4 {
        font-weight: 800 !important; }
    .slide .content .text h3 {
      display: none; }

.pagination .svi .allLink {
  background-color: #E5E5E5;
  border-radius: 6px;
  margin-left: 10px;
  padding: 10px 17px 11px 17px;
  color: #1F1A16;
  font-weight: 700;
  text-decoration: none;
  font-size: 16px; }

.pagination .active .allLink {
  background-color: #DA231B;
  color: #FFFFFF;
  text-decoration: none; }

.pagination .break-me a {
  color: black;
  background-color: #E5e5e5;
  border: 5px;
  padding: 10px 13px 11px 13px;
  border-radius: 6px;
  margin-left: 10px; }

.pagination .previous a {
  background-color: #E5E5E5;
  margin-left: 10px;
  padding: 10px 17px 11px 17px;
  border-radius: 6px; }
  .pagination .previous a::after {
    content: '';
    border: solid #1F1A16;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    position: relative; }

.pagination .next a {
  background-color: #E5E5E5;
  margin-left: 10px;
  padding: 10px 17px 11px 17px;
  border-radius: 6px; }
  .pagination .next a::after {
    content: '';
    border: solid #1F1A16;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: relative; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .wrapp_news {
    padding-top: 40px; }
  .slide .content .text {
    margin-left: 15px; }
    .slide .content .text p {
      height: 50px;
      overflow: hidden; }
    .slide .content .text a h6 {
      padding-top: 5px !important;
      padding-bottom: 40px; }
  .pagination {
    padding-top: 40px; }
    .pagination .svi .allLink {
      padding: 5px 12px 6px 12px;
      font-size: 14px; }
    .pagination .break-me a {
      padding: 3px 8px 5px 8px; }
    .pagination .previous a {
      padding: 3px 12px 6px 12px; }
      .pagination .previous a::after {
        padding: 3px; }
    .pagination .next a {
      padding: 3px 12px 6px 12px; }
      .pagination .next a::after {
        padding: 3px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .slide .content .text {
    margin-left: 15px; }
    .slide .content .text p {
      height: 55px;
      overflow: hidden; }
    .slide .content .text a h6 {
      padding-top: 5px;
      padding-bottom: 40px; } }

.ourServices .box {
  cursor: pointer;
  margin-bottom: 20px;
  border: 12px solid #F6F7F9;
  max-width: 350px;
  height: 120px;
  display: flex; }
  .ourServices .box .content {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 35px; }
    .ourServices .box .content svg {
      float: left; }
    .ourServices .box .content img {
      float: left;
      width: 55px;
      height: 55px;
      object-fit: contain;
      margin-right: 10px; }
    .ourServices .box .content .heding {
      text-align: start; }
      .ourServices .box .content .heding h3 {
        font-size: 24px;
        font-weight: 600;
        color: #1F1A16; }

.ourServices .activ {
  border: 12px solid #DA231B; }

.ourServices p {
  margin: 0;
  padding-bottom: 30px; }

.ourServices .dot {
  max-width: 255px;
  position: relative !important;
  margin-bottom: 35px; }
  .ourServices .dot .dots {
    position: absolute;
    bottom: -28px;
    right: -28px;
    z-index: -1; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .ourServices .box .content svg {
    margin-bottom: 10px;
    margin-right: 20px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .ourServices .box .content {
    margin-left: 10px; }
    .ourServices .box .content svg {
      margin-right: 10px;
      margin-bottom: 5px; }
    .ourServices .box .content .heding h3 {
      font-size: 20px; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .ourServices .box .content {
    margin-left: 15px; }
    .ourServices .box .content svg {
      margin-bottom: 10px;
      margin-right: 10px; } }

.contact input {
  border: 1px solid #D1D1D1;
  height: 44px;
  border-radius: 5px; }

.contact input::-webkit-input-placeholder {
  color: #232628; }

.contact input::-moz-placeholder {
  color: #232628; }

.contact input::-ms-input-placeholder {
  color: #232628; }

.contact input::placeholder {
  color: #232628; }

.contact label {
  text-transform: uppercase;
  font-size: 12px;
  color: #9F9F9F;
  padding-top: 10px; }

.contact textarea {
  height: 200px !important; }

.contact .btn {
  width: 140px;
  height: 40px;
  font-size: 18px;
  font-weight: 600;
  padding: 0; }

.contact .contancInfo {
  background-color: #1F1A16;
  height: 490px;
  border-radius: 5px; }
  .contact .contancInfo .content {
    color: white;
    padding: 30px 0 0 40px; }
    .contact .contancInfo .content h2 {
      font-size: 22px;
      font-weight: 600;
      line-height: 20px;
      padding-bottom: 40px;
      margin: 0; }
    .contact .contancInfo .content h3 {
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      margin: 0; }
      .contact .contancInfo .content h3 span {
        font-weight: 700; }
    .contact .contancInfo .content h4 {
      padding-top: 20px;
      font-size: 22px;
      font-weight: 600;
      line-height: 20px;
      padding-bottom: 13px;
      margin: 0; }
    .contact .contancInfo .content p {
      font-size: 17px;
      margin: 0; }
    .contact .contancInfo .content .contantDetail {
      padding-top: 23px; }
      .contact .contancInfo .content .contantDetail .fax svg {
        width: 24px;
        height: 24px;
        fill: #DA231B; }
      .contact .contancInfo .content .contantDetail .emailSVG svg {
        margin-top: -10px; }
      .contact .contancInfo .content .contantDetail h3 {
        padding-bottom: 16px; }
        .contact .contancInfo .content .contantDetail h3 svg {
          margin-right: 10px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .contact .btn {
    margin-bottom: 20px; } }

.location .container {
  position: relative; }
  .location .container .heading {
    margin-top: 88px; }
    .location .container .heading .pp {
      margin-top: 18px;
      margin-left: -30px; }
  .location .container .mapa {
    padding-top: 42px; }
    .location .container .mapa .onMap {
      background-color: white;
      min-height: 200px;
      width: 240px;
      position: absolute;
      right: 40px;
      bottom: 270px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); }
      .location .container .mapa .onMap p {
        font-size: 17px;
        padding: 15px 0 0 19px; }
    .location .container .mapa .map {
      height: 500px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .location .container .heading {
    margin-top: 40px; }
    .location .container .heading .pp {
      margin-left: unset; }
  .location .mapa .onMap {
    display: none; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .location .container .heading .pp {
    margin-left: unset; }
  .location .container .mapa .onMap {
    top: 300px; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .location .container .heading .pp {
    margin-left: unset; } }

.footer {
  position: relative;
  overflow: hidden; }
  .footer .ptag {
    position: relative;
    top: 170px; }
  .footer .redSection {
    top: 261px;
    background-color: #DA231B;
    height: 1920px;
    width: 64%;
    position: absolute;
    left: 0;
    border-radius: 180px;
    -webkit-transform: rotate(-28deg);
            transform: rotate(-28deg); }
  .footer .content {
    color: #fff;
    padding-top: 243px; }
    .footer .content ul {
      float: right;
      display: flex; }
      .footer .content ul li {
        display: inline;
        list-style: none; }
        .footer .content ul li a {
          display: inline;
          list-style: none;
          font-size: 17px;
          line-height: 20px;
          color: #1F1A16;
          font-weight: 600;
          margin-right: 42px;
          text-decoration: none; }
    .footer .content svg {
      margin-bottom: 45px;
      fill: #fff; }
    .footer .content .info {
      color: #1F1A16;
      padding-top: 77px;
      padding-bottom: 62px; }
      .footer .content .info svg {
        float: left;
        margin-right: 18px;
        fill: #DA231B; }
    .footer .content p {
      font-size: 20px;
      line-height: 24px; }
      .footer .content p span {
        font-size: 23px; }
  .footer .copyright p {
    color: #fff;
    padding-bottom: 29px;
    font-size: 20px; }
  .footer .copyright .nova p {
    color: #1F1A16; }
    .footer .copyright .nova p span a {
      text-decoration: none;
      color: #1F1A16; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .footer .footerFax {
    margin-top: 10px; }
  .footer .redSection {
    top: 227px;
    height: 100%;
    width: 100%;
    border-radius: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  .footer .content {
    padding-top: 243px; }
    .footer .content .logoFooter svg {
      width: 100%;
      margin-bottom: 40px; }
    .footer .content ul {
      display: none; }
    .footer .content svg {
      margin-bottom: 0; }
    .footer .content .info {
      text-align: start;
      padding-bottom: 30px;
      padding-top: 30px;
      color: #1F1A16; }
      .footer .content .info svg {
        float: left;
        margin-right: 15px;
        fill: white;
        height: 50px; }
      .footer .content .info p span br {
        display: none; }
    .footer .content p {
      color: #fff;
      font-size: 18px;
      line-height: 22px; }
      .footer .content p span {
        font-size: 20px; }
  .footer .copyright p {
    color: #fff;
    font-size: 18px; }
  .footer .copyright .nova p {
    color: #fff; }
    .footer .copyright .nova p span a {
      color: #fff; } }

@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .footer .content ul li a {
    margin-right: 26px; } }

@media screen and (max-width: 1023px) and (min-width: 991px) {
  .footer .content ul li a {
    margin-right: 12px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .footer .redSection {
    top: 196px;
    width: 95%; }
  .footer .content {
    color: #fff;
    padding-top: 243px; }
    .footer .content ul li a {
      margin-right: 14px; }
    .footer .content .logoFooter svg {
      width: 70%; }
    .footer .content ul li {
      font-size: 13px;
      margin-right: 7px; }
    .footer .content .info {
      color: #1F1A16;
      padding-top: 16px;
      padding-bottom: 75px; }
      .footer .content .info svg {
        float: left;
        margin-right: 16px;
        fill: #DA231B; }
    .footer .content p {
      font-size: 16px;
      line-height: 24px; }
      .footer .content p span {
        font-size: 18px; }
  .footer .copyright p {
    color: #fff;
    padding-bottom: 29px;
    font-size: 16px; }
  .footer .copyright .nova p {
    color: #1F1A16; }
    .footer .copyright .nova p span a {
      text-decoration: none;
      color: #1F1A16; } }

@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .footer .redSection {
    top: 196px;
    width: 78%; } }

@media screen and (max-width: 1366px) and (min-width: 1200px) {
  .footer .redSection {
    top: 210px;
    width: 75%;
    left: -80px; } }

@media screen and (max-width: 1680px) and (min-width: 1366px) {
  .footer .redSection {
    top: 240px;
    width: 77%;
    left: -100px; } }

html,
body {
  font-family: 'Barlow', sans-serif !important;
  overflow-x: hidden !important; }
  html h1,
  body h1 {
    font-weight: 400;
    font-size: 50px;
    line-height: 60px; }
    html h1 span,
    body h1 span {
      font-weight: 800; }
    html h1::after,
    body h1::after {
      content: '';
      display: block;
      position: relative;
      background-color: #DA231B;
      height: 4px;
      width: 100px;
      margin-top: 10px;
      margin-bottom: 26px; }
  html h4,
  body h4 {
    font-size: 30px;
    line-height: 36px; }
  html p,
  body p {
    margin: 0;
    font-size: 23px;
    line-height: 28px;
    font-weight: 400; }
    html p span,
    body p span {
      font-weight: 700; }
  html .btn,
  body .btn {
    color: #FFFFFF;
    font-weight: 500;
    border-radius: 10px;
    background-color: #DA231B; }

.no-ma-right {
  margin-right: 0 !important; }

.no-ma-left {
  margin-left: 0 !important; }

.no-pa {
  padding: 0; }

.header {
  margin-top: 99px;
  margin-bottom: 45px; }
  .header svg {
    margin-right: 30px; }
  .header .certs-image {
    height: 150px; }
  .header h3 {
    font-size: 42px;
    color: #1F1A16;
    line-height: 50px;
    font-weight: 400; }
    .header h3 span {
      font-weight: 700; }
  .header p {
    padding-top: 49px;
    padding-bottom: 38px; }
  .header .btn {
    width: 290px;
    height: 60px;
    font-size: 25px; }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .header {
    margin-top: 50px; } }

.button .btn {
  background: url(/static/media/mouseScroll.fa52088f.svg) center;
  background-size: contain;
  width: 24px;
  height: 62px;
  border: 0;
  position: relative;
  text-decoration: none; }
  .button .btn:hover {
    background-color: white !important;
    border: 0 !important; }

.whatWeDo {
  margin-top: 77px;
  text-align: center; }
  .whatWeDo h4 {
    color: #DA231B;
    font-weight: 600; }
  .whatWeDo h1 {
    text-align: center;
    font-weight: 400; }
    .whatWeDo h1::after {
      left: 47.5%; }
  .whatWeDo .content-text {
    margin-bottom: 64px; }
  .whatWeDo .box {
    margin-bottom: 40px;
    border: 16px solid #F6F7F9;
    height: 320px;
    display: flex; }
    .whatWeDo .box .content {
      display: block;
      margin-top: auto;
      margin-bottom: auto;
      width: 100%; }
      .whatWeDo .box .content h4 {
        color: #1F1A16;
        text-transform: uppercase; }
      .whatWeDo .box .content img {
        max-height: 100px;
        margin-bottom: 8px; }
  .whatWeDo .color {
    border: 0;
    background-color: #DA231B; }
    .whatWeDo .color .content a {
      text-decoration: none !important; }
      .whatWeDo .color .content a h4 {
        color: #FFFFFF; }
        .whatWeDo .color .content a h4::after {
          content: '';
          border: solid #ffffff;
          border-width: 0 4px 4px 0;
          display: inline-block;
          padding: 8px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          position: relative;
          left: 4.3px; }

.gallery .container .heading {
  margin-top: 90px; }
  .gallery .container .heading h1 {
    padding-bottom: 26px; }
  .gallery .container .heading p {
    float: left;
    margin: 0;
    padding-top: 20px; }
  .gallery .container .heading .btn {
    width: 250px;
    height: 50px;
    font-size: 20px;
    float: right; }

.gallery .container .galerija {
  padding-top: 104px; }
  .gallery .container .galerija img {
    height: unset; }
  .gallery .container .galerija .slika {
    margin-bottom: 30px !important;
    object-fit: contain; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .gallery .container .heading {
    margin-top: 40px; }
    .gallery .container .heading h1 {
      padding-bottom: unset; }
    .gallery .container .heading p {
      padding-top: unset; }
    .gallery .container .heading .btn {
      float: none;
      margin-top: -15px;
      width: 210px;
      height: 40px;
      font-size: 16px; }
  .gallery .container .galerija {
    padding-top: 50px !important; }
    .gallery .container .galerija .slika {
      width: 100% !important; } }

.news {
  margin-top: 55px;
  background-color: #F6F7F9; }
  .news h1 {
    padding-top: 80px; }
    .news h1::after {
      content: '';
      display: block;
      position: relative;
      background-color: #DA231B;
      height: 4px;
      width: 100px;
      margin-top: 10px;
      margin-bottom: 26px; }
  .news .slick-slider {
    width: 100%;
    position: relative;
    padding-top: 52px; }
    .news .slick-slider .slick-list {
      padding: 3px; }
    .news .slick-slider .slick-arrow:hover {
      background-color: #DA231B;
      height: 46px;
      width: 46px; }
      .news .slick-slider .slick-arrow:hover svg {
        fill: white; }
    .news .slick-slider .slick-next {
      position: absolute;
      right: 15px;
      top: 0;
      background: #fff;
      color: transparent;
      height: 40px;
      width: 40px;
      z-index: 1;
      border: 0; }
      .news .slick-slider .slick-next:before {
        content: '' !important;
        border: solid #111111;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        position: relative; }
    .news .slick-slider .slick-prev {
      position: absolute;
      right: 65px;
      top: 0;
      left: unset;
      background-color: #fff;
      color: transparent;
      height: 40px;
      width: 40px;
      z-index: 1;
      border: 0; }
      .news .slick-slider .slick-prev:before {
        content: '' !important;
        border: solid #111111;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        position: relative; }
    .news .slick-slider .slide img {
      max-height: 340px;
      object-fit: cover;
      width: 97%; }
    .news .slick-slider .slide .content {
      margin-top: 40px;
      display: flex;
      width: 97%; }
      .news .slick-slider .slide .content .date {
        border-radius: 10px;
        color: #FFFFFF;
        text-align: center;
        float: left;
        background-color: #DA231B;
        max-height: 140px;
        min-width: 140px; }
        .news .slick-slider .slide .content .date h2 {
          font-size: 64px;
          font-weight: 600; }
          .news .slick-slider .slide .content .date h2::after {
            content: '';
            display: block;
            position: relative;
            width: 120px;
            height: 1px;
            background-color: #FFFFFF;
            left: 6px; }
        .news .slick-slider .slide .content .date p {
          font-size: 23px;
          font-weight: 600; }
      .news .slick-slider .slide .content .text {
        margin-left: 40px; }
        .news .slick-slider .slide .content .text a {
          color: #1F1A16;
          text-decoration: none; }
          .news .slick-slider .slide .content .text a h6 {
            padding-top: 24px;
            font-size: 23px;
            font-weight: 400;
            line-height: 28px;
            padding-bottom: 94px; }
            .news .slick-slider .slide .content .text a h6 .btn {
              margin-right: 8px;
              text-align: center;
              background: url(/static/media/arrowRight.d1b5f880.svg) center no-repeat;
              position: relative;
              text-decoration: none;
              width: 32px;
              height: 32px;
              border-radius: 100%;
              background-color: #1F1A16; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  html,
  body {
    overflow-x: hidden !important; }
    html h1,
    body h1 {
      font-size: 30px;
      line-height: 45px; }
    html h4,
    body h4 {
      font-size: 25px;
      line-height: 30px; }
    html p,
    body p {
      font-size: 18px;
      line-height: 24px; }
  .header {
    margin-top: 0px; }
    .header svg {
      display: none; }
    .header h3 {
      padding-top: 30px;
      font-size: 37px;
      line-height: 40px; }
    .header p {
      padding-top: 20px; }
    .header .btn {
      width: 210px;
      height: 40px;
      font-size: 18px; }
  .whatWeDo {
    margin-top: 50px; }
    .whatWeDo h1::after {
      left: 37.5%; }
    .whatWeDo h4 {
      margin-top: 30px; }
    .whatWeDo .no-pa-up {
      margin-top: 0; }
  .news h1 {
    padding-top: 40px; }
  .news .slick-slider {
    padding-top: 40px; }
    .news .slick-slider .slick-arrow:hover {
      height: 39px;
      width: 39px; }
    .news .slick-slider .slick-next {
      top: -10px;
      height: 35px;
      width: 35px;
      right: 0; }
    .news .slick-slider .slick-prev {
      right: 37px;
      top: -10px;
      height: 35px;
      width: 35px; }
    .news .slick-slider .slide .content .date h2 {
      padding-top: 12px;
      font-size: 54px; }
    .news .slick-slider .slide .content .date p {
      font-size: 18px; }
    .news .slick-slider .slide .content .text {
      margin-left: 10px; }
      .news .slick-slider .slide .content .text a h6 {
        padding-top: 10px;
        padding-bottom: 20px; }
      .news .slick-slider .slide .content .text p {
        height: 50px;
        overflow: hidden; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .whatWeDo h1::after {
    left: 44.5%; }
  .news .slick-slider .slick-arrow:hover {
    height: 39px;
    width: 39px; }
  .news .slick-slider .slick-next {
    top: -10px;
    height: 35px;
    width: 35px; }
  .news .slick-slider .slick-prev {
    right: 58px;
    top: -10px;
    height: 35px;
    width: 35px; }
  .news .slick-slider .slide .content .text {
    margin-left: 10px; }
    .news .slick-slider .slide .content .text a h6 {
      padding-top: 10px; }
    .news .slick-slider .slide .content .text p {
      height: 55px;
      overflow: hidden; }
  .HowWeWork .content .image {
    width: 100%;
    padding-bottom: 20px; }
  .HowWeWork .companyinfo {
    padding-top: 40px; } }

.btn {
  outline: none !important;
  border: 0 !important; }

.radovi svg {
  width: 100%; }

